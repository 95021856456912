import React from 'react';
import { Link } from 'gatsby';
import SEO from '../components/seo/seo';

/**
 * 404 Page
 */
const PageNotFound = () => (
  <>
    <SEO title="404: Page not found" />
    <div className="search wrapMedium">
      <h1 className="headingPage">Sorry, That Page Is Missing</h1>
      <p>
        Please visit <Link to="/">Home</Link> for the latest episodes, view a
        particular show’s episodes via the <Link to="/podcasts">Podcasts</Link>{' '}
        page, or <Link to="/search">search for a topic</Link>.
      </p>
    </div>
  </>
);

export default PageNotFound;
